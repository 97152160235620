import { startTransition, StrictMode } from 'react';
import { hydrateRoot } from 'react-dom/client';
import { HydratedRouter } from 'react-router/dom';

declare global {
  interface Window {
    ENV: {
      SENTRY_DSN: string;
      SENTRY_ENVIRONMENT: string;
      STRIPE_PUBLISHABLE_KEY: string;
    };
  }
}

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <HydratedRouter />
    </StrictMode>
  );
});
